@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
html {
	scroll-behavior: smooth;
}
a {
	text-decoration: none;
	color: grey;
}

button {
	border: none;
	outline: none;
	cursor: pointer;
}
.hero-text {
	font-family: 'Poppins', sans-serif;
	color: #000;
	font-size: 36px;
	font-weight: 600;
}

.hero-description {
	font-family: 'Montserrat', sans-serif;
	color: #000;
	font-size: 19px;
	font-weight: 500;
	margin-top: 1rem;
	margin-bottom: 3rem;
}
.calculator-button-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 200px;
}
.next-button {
	width: 320px;
	background: #85bb65;
	border-color: #85bb65;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	border-radius: 5px;
	height: 50px;
}
.next-button:hover {
	background: #228b22;
	border-color: #228b22;
}
.grey-button {
	width: 320px;
	background: #f7f7f7;
	border-color: #f7f7f7;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	border-radius: 5px;
	height: 50px;
	font-family: 'Montserrat', sans-serif;
	font-size: 19px;
	font-weight: 500;
	color: #000;
	text-transform: uppercase;
	text-decoration: none;
}
.grey-button-text {
	font-family: 'Montserrat', sans-serif;
	font-size: 19px;
	font-weight: 500;
	color: #000;
	text-transform: uppercase;
	text-decoration: none;
}
.grey-button:hover {
	background: #ececec;
	border-color: #ececec;
	color: #000;
}
.next-button-text {
	font-family: 'Montserrat', sans-serif;
	font-size: 19px;
	font-weight: 500;
	text-transform: uppercase;
	text-decoration: none;
}
.choose-button {
	width: 250px;
	background: #f7f7f7;
	border-color: #f7f7f7;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #000;
	border-radius: 5px;
	height: 50px;
	font-family: 'Montserrat', sans-serif;
	font-size: 19px;
	font-weight: 500;
	text-transform: uppercase;
	text-decoration: none;
}
.choose-button:hover {
	background: #85bb65;
	border-color: #85bb65;
	color: white;
}
.choose-button:hover .choose-button-text {
	color: white;
}
.next-button-active {
	background: #85bb65;
	border-color: #85bb65;
}
.next-button-disabled {
	background: #f7f7f7;
	border-color: #f7f7f7;
	color: #000;
}
.p-button:focus {
	box-shadow: none;
}
.p-inputnumber-input {
	height: 50px;
	padding: 10px;
}

.calculator-title {
	color: #000;
	font-family: Poppins;
	font-size: 36px;
	font-style: normal;
	font-weight: 800;
	line-height: normal;
	text-align: center;
	margin-bottom: 30px;
}
.calculator-description {
	color: #000;
	text-align: center;
	font-family: Montserrat;
	font-size: 19px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin-bottom: 30px;
}
.calculator-question {
	color: #000;
	text-align: center;
	font-family: Montserrat;
	font-size: 22px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-bottom: 50px;
}
.calculator-sub-question {
	color: #000;
	text-align: center;
	font-family: Montserrat;
	font-size: 22px;
	font-weight: 400;
	font-style: italic;
	line-height: normal;
	margin-top: -30px;
	margin-bottom: 30px;
}
.calculator-answer {
	display: flex;
	flex-direction: row;
	color: #000;
	font-family: Montserrat;
	font-size: 19px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	gap: 10px;
}

.checkbox-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 10px;
}
.buttons-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	gap: 10px;
	margin-top: 50px;
}
.p-progressbar .p-progressbar-value {
	background-color: #85bb65;
}
.progressbar-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 10vh;
}
.progress {
	width: 50%;
}

.result-wrapper {
	background-image: url('/public/images/result_bg.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center top;
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 100vh;
	padding-left: 150px;
	width: 100%;
	padding-top:50px;
}
.result-input-wapper {
	width: 700px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-top: 20px;
	background-color: #fff;
	padding: 50px;
	border-radius: 10px;
	margin-bottom: 25px;
}
.result-title {
	width: 700px;
	color: #fff;
	font-family: Poppins;
	font-size: 36px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin-bottom: 30px;
}
.result-input-text {
	color: #000;
	font-family: Montserrat;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin-bottom: 10px;
}
.result-acceptance-wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 20px;
}
.result-acceptance {
	margin-left: 10px;
	color: #000;
	font-family: Montserrat;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.result-text {
	color: #000;
	font-family: Montserrat;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-top: 20px;
}
.result-input {
	margin-bottom: 25px;
}
.p-inputtext {
	height: 50px;
	padding: 10px;
	width: 100%;
}
.p-inputtext:enabled:focus {
	border-color: #85bb65;
	box-shadow: 0 0 0 0.2rem rgba(133, 187, 101, 0.25);
}
.p-checkbox.p-highlight .p-checkbox-box {
	border-color: #85bb65;
	background-color: #85bb65;
}
.p-radiobutton.p-highlight .p-radiobutton-box {
	border-color: #85bb65;
	background-color: #85bb65;
}
.p-radiobutton:not(.p-disabled):has(.p-radiobutton-input:hover) .p-radiobutton-box {
	border-color: #85bb65;
}
.p-inputtext:enabled:hover {
	border-color: #85bb65;
}
.p-button {
	background-color: #85bb65;
	border-color: #85bb65;
	color: #fff;
}
.p-inputtextarea {
	height: 100px !important;
}
.landing_text_wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100vh;
	width: 60%;
	margin-left: 10%;
	margin-right: 10%;
}
.calculator_wrapper {
	width: 75%;
}
.landing_image_wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100vh;
	background-image: url('./images/hero_desktop.jpg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	width: 40%;
}
.landing_mobile_image {
	display: none;
}
.landing_main {
	display: flex;
	min-height: 100vh;
	background-color: #fff;
}
.calculator_main {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 90vh;
}
.checkbox-outer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	gap: 50px;
}
.gdpr {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 30px;
	margin-top:50px;
	padding-bottom:20px;
}

@media screen and (max-width: 768px) {
	.landing_image_wrapper {
		display: none;
	}
	.landing_mobile_image {
		display: flex;
		width: 100%;
		height: 200px;
		background-image: url('./images/hero_mobile.jpg');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center bottom;
	}
	.landing_main {
		flex-direction: column;
	}
	.landing_text_wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 95%;
		height: auto;
		margin-top: 20px;
		margin-left: 20px;
		margin-right: 20px;
	}
	.hero-text {
		font-family: 'Poppins', sans-serif;
		font-size: 26px;
		font-weight: 600;
		margin-top: 0rem;
	}
	.calculator-title {
		font-size: 26px;
	}
	.calculator-description {
		font-size: 16px;
	}
	.calculator_wrapper {
		width: 95%;
	}
	.calculator-question {
		font-size: 22px;
	}
	.checkbox-outer {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: center;
		align-items: flex-start;
		padding-left: 20px;
		gap: 20px;
	}
	.calculator-button-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 20px;
	}
	.calculator_main {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 75vh;
	}
	.progressbar-wrapper {
		width: 100%;
	}
	.progress {
		width: 95%;
	}
	.calculator-answer {
		font-size: 16px;
	}
	.calculator-sub-question {
		font-size: 16px;
	}
	.result-wrapper {
		padding: 20px;
		width: 100%;
	}
	.result-title {
		width: 100%;
		font-size: 26px;
		text-align: center;
		margin-bottom: 0px;
	}
	.result-input-wapper {
		width: 100%;
		padding: 0px 20px 20px 20px;
	}
	.checkbox-wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		background-color: #fff;
	}
	.checkbox-wrapper-selected {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
	}
}
